@import "~@fortawesome/fontawesome-free/css/all.css";
.ol-boxnew {
  box-sizing: border-box;
  border: 5px solid;
  height: calc(180vh - 65px);
  /*display: flex;*/
  position: relative;
  /* border-color: #2E86C1;*/
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}


.example-handle {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ccc;
  cursor: move;
  width: 24px;
  height: 24px;
  z-index:100;
}

.faksimile-box {
  width: 100px;
  height: 100px;
  border: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

  .faksimile-box:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    z-index: 100;
  }

.faksimile-mini-boundary {
  width: 110px;
  height: auto;
  max-width: 100%;
  /* border: dotted #ccc 2px;*/
  display: flex;
}

.text {
  /*color: #2E86C1;*/
  font-size: 60px;
  /*text-shadow: 3px 2px 4px rgba(0, 0, 0, 0.87);*/
  text-shadow: -1px -1px 1px hsl(190,50%,90%), 1px 1px 1px hsl(190,50%,50%);
}


svg {
  position: absolute;
  top: 0;
  left: 0;
}
.defaultSVG svg{
  position: relative;
}

.line {
  stroke: cyan;
  stroke-width: 1.5px;
  stroke-linecap: square;
}

.handle {
  fill: none;
  pointer-events: all;
  stroke: blue;
  stroke-width: 2px;
  cursor: move;
}

.handlemiddle {
  fill: none;
  pointer-events: all;
  stroke: green;
  stroke-width: 2px;
  cursor: move;
}

.draggable {
  fill: none;
  pointer-events: all;
  stroke: red;
  stroke-width: 2px;
  cursor: move;
}

.fa-stack {
  font-size: 0.5em;
}

i {
  vertical-align: middle;
}

.resize{
  border: 2px solid;
  padding: 20px;
  resize: both;
  overflow: auto;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
